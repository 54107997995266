<template>
  <div>
    Display Folder Structure Attributes Here...
  </div>
</template>

<script>
export default {
  name: 'FolderStructureAttributes',
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
</style>
